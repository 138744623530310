<template>
    <div class="modal-recommend">
        <div class="head flex-row flex-between items-start">
            <div class="title" v-html="$translate('RECOMMEND_TITLE')" />
            <i @click="$emit('close')" class="zmdi zmdi-close" />
        </div>
        <div v-if="recommendBody && chatUser" class="content-body" v-html="recommendBody" />
        <div class="hr m-b-0" />

        <div class="scroll-area single-recommend">
            <div class="payload flex-row">
                <div class="left" :class="{ 'p-r-16': !$store.getters.isMobile }">
                    <div class="section-title m-t-24 m-b-16" v-html="$translate('APPEAL_MESSAGE')" />
                    <TextareaWithAutoTexts v-model="recommendMessage" class="bottom" />

                    <div class="flex-row items-center m-t-12 m-b-24">
                        <div class="badge-tips flex-wrap" v-html="'Tips!'" />
                        <div class="description-small" v-html="$translate('RECOMMEND_HELP')" />
                    </div>
                </div>

                <!--                <div class="hr" />-->
                <div class="right">
                    <div class="section-title flex-row items-center m-b-16">
                        <span class="flex-wrap" v-html="$translate('CHOOSE_PHOTO')" />
                        <span class="desc" v-html="`(${$translate('CHOOSE_PHOTO_DESC')})`" />
                    </div>
                    <div class="photos" v-if="chatUser">
                        <div
                            class="img-profile"
                            @click="onClickPhoto(photo)"
                            v-lazy:background-image="photo.url"
                            :key="idx"
                            v-for="(photo, idx) in chatUser.photos"
                        >
                            <Checkbox class="white" v-model="photo.checked" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="$store.getters.isMobile" class="blank" />

        <div :class="$store.getters.isMobile ? 'footer-container-fixed' : 'footer-container-relative'">
            <div class="hr m-t-0 m-b-0" />
            <div class="buttons bottom">
                <div class="flex-row">
                    <button @click="$emit('close', 0)" class="btn-default" v-html="$translate('CANCEL')" />
                    <button
                        ref="send"
                        @click="onSend"
                        :class="disabled ? 'disabled' : 'btn-primary'"
                        v-html="$translate('RECOMMEND')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import suggestService from '@/services/suggest'

export default {
    name: 'ModalRecommend',
    props: ['options'],
    data: () => ({
        recommendMessage: null,
        recommendBody: null,
        chatUser: {},
    }),
    computed: {
        autoGenerated() {
            return (this.chatUser.auto_intro || []).join('<br><br>').replace(/\n/g, '<br>')
        },
        recommendedUser() {
            return this.options.recommendedUser
        },
        nickname() {
            return this.recommendedUser.nickname || this.recommendedUser.profile.nickname
        },
        disabled() {
            return !this.recommendMessage || !this.twoPhotosChecked
        },
        twoPhotosChecked() {
            return (this.chatUser.photos || []).filter(p => p.checked).length === 2
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            this.recommendMessage = this.$translate('RECOMMEND_DEFAULT_MESSAGE').replace(/%s/g, this.nickname)

            this.chatUser = await this.$store.dispatch('loadUserDetail', { userId: this.$store.getters.chat.user.id })
            this.recommendBody =
                this.options && this.recommendedUser
                    ? this.$translate('RECOMMEND_BODY')
                          .replace(/%s/, this.nickname)
                          .replace(/%s/, this.$nameOrNick(this.chatUser))
                          .replace(/%s/, this.nickname)
                    : null

            const addCheckedToPhotos = () => {
                ;(this.chatUser.photos || []).forEach((p, idx) => this.$set(p, 'checked', idx < 2))
            }
            addCheckedToPhotos()
        },
        async onSend() {
            const send = this.$refs.send
            if (this.disabled) {
                this.$animate.shake(send)
                return
            }

            this.onConfirmSendRequests()
        },
        onClickPhoto(photo) {
            if (!photo.checked && this.twoPhotosChecked) {
                this.$toast.error('CHOOSE_PHOTO_DESC')
                return
            }

            photo.checked = !photo.checked
        },
        async onConfirmSendRequests() {
            if (!this.twoPhotosChecked) {
                return
            }

            try {
                this.$loading(true)
                const photos = this.chatUser.photos.filter(p => p.checked)
                const data = await suggestService.request({
                    user_id: this.recommendedUser.id,
                    source_id: this.chatUser.id,
                    message: this.recommendMessage,
                    referred: 'recommend_user',
                    photo1_id: photos[0].id,
                    photo2_id: photos[1].id,
                })
                this.$store.commit('markAsIntroducedTo', [this.recommendedUser.id])
                this.$toast.success(data.msg)
                this.$emit('close', 1)
            } catch (e) {
                this.$toast.error(e.data)
            }
            this.$loading(false)
        },
    },
}
</script>
<style scoped lang="scss">
::v-deep .textarea {
    height: 450px !important;
}
</style>
